import React, { lazy } from 'react';
import Routes from './routes/routes';
import styles from './App.module.scss';
import { Box } from '@mantine/core';
import { useLocation } from 'react-router-dom';
const Toolbar = lazy(() => import('atoms/Toolbars/Toolbar'));
function App() {
	const location = useLocation();
	return (
		<div className={styles['app']}>
			<Box mih="100vh" style={{ backgroundColor: 'white' }}>
				<Routes />
			</Box>
			{['/', '/bookings', '/settings'].includes(location.pathname) && (
				<Box
					w="100%"
					style={{ position: 'sticky', bottom: '0', zIndex: 9999999 }}
					px="30px"
					py="10px"
				>
					<Toolbar />
				</Box>
			)}
		</div>
	);
}

export default App;
