import React from 'react';
import ReactDOM from 'react-dom/client';
import './app-config';
import AppWrapper from './AppWrapper';
import reportWebVitals from './reportWebVitals';
import { MantineProvider } from '@mantine/core';
import 'index.module.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<MantineProvider withGlobalStyles withNormalizeCSS>
		<AppWrapper />
	</MantineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
