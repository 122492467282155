import React, { lazy, useContext, useEffect } from 'react';
import {
	useRoutes,
	Navigate,
	useNavigate,
	useLocation,
	useSearchParams,
	useParams,
} from 'react-router-dom';
// import { LocalStorage } from 'utils/localStorage';
// import useUserStore from 'stores/UserStore';
// import useAuthStore from 'stores/AuthStore';

const Home = lazy(() => import('screens/Home/Home'));
const BookNets = lazy(() => import('screens/BookNets/BookNets'));
const Bookings = lazy(() => import('screens/Bookings/Bookings'));
const SelectBookTime = lazy(() => import('screens/SelectBookTime/SelectBookTime'));
const ConfirmBooking = lazy(() => import('screens/ConfirmBooking/ConfirmBooking'));
const BookingDetails = lazy(() => import('screens/BookingDetails/BookingDetails'));
const Settings = lazy(() => import('screens/Settings/Settings'));
const Login = lazy(() => import('screens/Login/Login'));
const Signup = lazy(() => import('screens/Signup/Signup'));
const Account = lazy(() => import('screens/Account/Account'));
const Payments = lazy(() => import('screens/Payments/Payments'));
const AccountDeletion = lazy(() => import('screens/AccountDeletion/AccountDeletion'));
const PasswordReset = lazy(() => import('screens/PasswordReset/PasswordReset'));
function Routes() {
	// const navigate = useNavigate();
	// const location = useLocation();
	// const UserStore = useUserStore((s) => s);
	// const AuthStore = useAuthStore();
	// const rootRoutePath = location.pathname.split('/')[1];

	// const isAccessibleRoute = () => {
	//     return true
	// };

	// useEffect(() => {
	//     if (!AuthStore.isLogin() && location.pathname != "/signup") {
	//         // navigate('/login');
	//         // LocalStorage.clear();
	//     }

	//     // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [AuthStore.isLogin()]);

	// useEffect(() => {
	//     if (AuthStore.isLogin()) {
	//         const init = async () => {
	//             if (!isAccessibleRoute()) {
	//                 // navigate('/404');
	//                 return;
	//             }
	//         };
	//         init();
	//     }
	// }, [location.pathname]);

	const routes = useRoutes([
		{ path: '/', element: <Home /> },
		{ path: '/book-nets', element: <BookNets /> },
		{ path: '/select-book-time', element: <SelectBookTime /> },
		{ path: '/confirm-booking', element: <ConfirmBooking /> },
		{ path: '/booking-details/:id', element: <BookingDetails /> },
		{ path: '/bookings', element: <Bookings /> },
		{ path: '/settings', element: <Settings /> },
		{ path: '/login', element: <Login /> },
		{ path: '/signup', element: <Signup /> },
		{ path: '/account', element: <Account /> },
		{ path: '/payments', element: <Payments /> },
		{ path: '/account-deletion', element: <AccountDeletion /> },
		{ path: '/reset-password/:email', element: <PasswordReset /> },
		// { path: '/404', element: <Error404 /> },
		{ path: '*', element: <Navigate to="/" replace={true} /> },
	]);

	return <>{routes}</>;
}
export default Routes;
