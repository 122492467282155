

export const SERVER_URL = window.bookmy.server_url;
export const BASE_API_URL = `${SERVER_URL}/slb_web/apis`;

export const DEFAULT_USER_META = {
    ap: 'besc',
    os: 2,
    av: '1.0.0',
    u: 0,
    d: 0,
    i: 'web',
};

export const STRIPE_SCRIPT_URL = 'https://js.stripe.com/v3/';

export const VENUE_GEO_CODE = {
    lat: -42.87130541065064,
    lng: 147.32836069110448
}