// @ts-nocheck
import React, { lazy, Suspense, useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { DEFAULT_USER_META } from 'utils/constants/core.js';
import { LocalStorage } from 'utils/localStorage';

const Loading = lazy(() => import('atoms/Loadings/Loading'));
const queryClient = new QueryClient();
window.queryClient = queryClient;

if (!LocalStorage.get('meta')) {
	LocalStorage.set('meta', DEFAULT_USER_META);
}

export default function AppWrapper() {
	return (
		<>
			<div
				style={{
					minHeight: '100vh',
					margin: '0 auto',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					position: 'relative',
					background: "black"
				}}
			>
				<Router>
					<Suspense fallback={<Loading />}>
						<Notifications position="top-center" zIndex={1000} autoClose={5000} />
						<ModalsProvider>
							<QueryClientProvider client={queryClient}>
								{/* <ReactQueryDevtools /> */}
								<App />
							</QueryClientProvider>
						</ModalsProvider>
						{/* <Loading /> */}
					</Suspense>
				</Router>
			</div>
		</>
	);
}
