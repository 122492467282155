//app config

const APP_MODE_TYPES = ['localhost', 'dev', 's8', 'staging', 'live'];
const APP_MODE = 'localhost';

if (process.env.NODE_ENV !== 'production') {
	switch (APP_MODE) {
		case 'localhost':
		case 'dev':
		case 's8':
			window.bookmy = {
				server_url: 'https://nhcc-demo.smartlockbooking.com.au',
			};
			break;
		case 'staging':
			window.bookmy = {
				server_url: 'https://nhcc-demo.smartlockbooking.com.au',
			};
			break;
		case 'live':
			window.bookmy = {
				server_url: 'https://nhcc.smartlockbooking.com.au',
			};
			break;
	}
}
// define a new console
const newConsole = (function (console) {
	return {
		log: function (text) {
			// console.log(text);
		},
		info: function (text) {
			// console.log(text);
		},
		warn: function (text) {
			// console.log(text);
		},
		error: function (text) {
			// console.log(text);
		},
	};
})(window.console);

if (process.env.NODE_ENV === 'production') {
	window.console = newConsole;
}
